.collect-data-custom-html {
  font-family: 'SF Pro Display';
  margin-bottom: 8px;
}

.collect-data-custom-html .link-button {
  background-color: #f2e5ff;
  width: '100%';
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 56px;
  border-radius: 8px;
  text-decoration-line: none;
}

.collect-data-custom-html .link-button span {
  color: #7f00ff !important;
  /* button - Semibolt */
  font-family: 'SF Pro Display';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: 0.32px;
}
